import React from 'react';
import { Helmet } from 'react-helmet';
import { theme } from '../styledComponents/theme';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../styledComponents/globalStyles';
import { Link } from 'gatsby';

import Header from '../components/UI/Header/Header';
import PageSection from '../components/PageSection/PageSection';
import Heading from '../components/UI/Heading/Heading';
import Button from '../components/UI/Button/Button';


const PageSectionWithMargin = styled(PageSection)`
    height: 100vh;
    justify-content: center;
`;

const CustomButton = styled(Button)`
    margin-top: 50px;
`;

const CustomHeading = styled(Heading)`
    margin-bottom: 0;
`;


const fourofour = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <Helmet>
                    <title>Issy Dennis</title>
                    <meta name="description" content="Cardiff based React developer" />
                </Helmet>

                <Header id='top' svgBackground />

                <PageSectionWithMargin align='center' background={props => props.theme.colors.lightFont}>

                    <CustomHeading>404</CustomHeading>
                    <Heading minor>Oops...You appear to have taken a wrong turn.</Heading>
                    <Link to='/'><CustomButton buttonColor={props => props.theme.colors.primaryAccent}>Go back</CustomButton></Link>

                </PageSectionWithMargin>
            </>
        </ThemeProvider>
    )
}


export default fourofour;